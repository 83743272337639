import { template as template_e37dd17664e54838a43ff814a3fa5ef1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e37dd17664e54838a43ff814a3fa5ef1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
