import { template as template_9a978563eefc4f13a61744ab9ff6d828 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_9a978563eefc4f13a61744ab9ff6d828(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
