import { template as template_03f752d052cb4c348305744553a5e0cc } from "@ember/template-compiler";
const WelcomeHeader = template_03f752d052cb4c348305744553a5e0cc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
