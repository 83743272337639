import { template as template_a24e40d8577846e6919b6a3c443a49bd } from "@ember/template-compiler";
const SidebarSectionMessage = template_a24e40d8577846e6919b6a3c443a49bd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
